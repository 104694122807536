import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Logo } from '../../assets/logo';
import { icons, subSectionIcons } from '../../landingPage';
import hamburger from '../../assets/hamburger.svg';
import { sections } from '../../../dustControl/utils/constants';

const Sidebar = (props) => {
  const {
    sidebarActive,
    selectedTab,
    setSelectedTab,
    subSections,
    code,
    setSidebarActive,
  } = props;
  const navigate = useNavigate();

  const subMenu2 = subSections.map((title, index) => ({
    title,
    activeClass: selectedTab === index ? `active` : undefined,
    onClick: () => {
      setSelectedTab(index);
    },
    icon: subSectionIcons[code][index],
  }));

  const onHamburgerClick = () => {
    setSidebarActive(!sidebarActive);
  };

  return (
    <div
      className={`sidebar-container ${
        sidebarActive ? `sidebar-active` : undefined
      }`}
      style={{ background: '#FCFCFD' }}
    >
      <div className="hamburger-container">
        {sidebarActive && (
          <button
            className="bg-transparent border-0"
            onClick={() => navigate('/landing-page')}
          >
            <Logo textColor="black" />
          </button>
        )}
        <img
          onClick={onHamburgerClick}
          className={`hamburger ${!sidebarActive && `flip-icon`}`}
          src={hamburger}
          alt="hamburger"
        />
      </div>
      {sidebarActive && (
        <>
          <div
            style={{
              borderTop: '1px solid #EFEFEF',
              height: '2px',
              width: '100%',
              margin: '20px 0px 36px 0px',
            }}
          />
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <img src={icons[code]} alt="icon" />
            <div
              className="font-weight-bold p-3"
              style={{ fontSize: '16px', fontWeight: '600', color: '#1A1A1A' }}
            >
              {sections[code]}
            </div>
          </div>

          <div className="sidebar-menu">
            {subMenu2.map((item) => (
              <div
                key={item.title}
                className={`sub-menu-icon ${item.activeClass}`}
                onClick={item.onClick}
                style={{ color: '#1A1A1A' }}
              >
                {item.icon && (
                  <div className="iconContainer">
                    <img src={item.icon} alt="icon" />
                  </div>
                )}
                <div>{item.title}</div>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default Sidebar;
