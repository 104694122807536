import React from 'react';
import { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Typography,
} from '@mui/material';
import { getPickupEndTime, getPickupTotalDuration } from '../TrackBin/helper';
import {
  BinPickup,
  BinHistory,
  TruckData,
  PickupStatus,
} from '../../utils/types';
import { Spinner } from 'react-bootstrap';
import './styles.css';
import { TrackBinModal } from '../TrackBin';
import { findTruckDataByName, getFormattedTime } from '../../utils/helpers';
import { HistoryCategory, HistoryTableProps } from './types';
import downloadIcon from '../../../dustControl/assets/icons/download-prestart.svg';
import { getPickupStatusWithDot } from '../TrackBin/JobDetails';
import { renderTableHeadersForHistory } from './renders';
import { upperFirst } from 'lodash';

const HistoryTable: React.FC<HistoryTableProps> = (
  props: HistoryTableProps,
) => {
  const [isDetailModalOpen, setDetailModalOpen] = useState(false);
  const [selectedBinPickup, setSelectedBinPickup] = useState<
    BinPickup | undefined
  >();

  if (props.loading) {
    return (
      <div className="text-center py-4">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }

  if (!props.data.length) {
    return <div>No history data available.</div>;
  }

  const formatJobCycleDuration = (pickup: BinPickup): string => {
    if (!pickup.events.length) return '-';

    const startTime = new Date(pickup.events[0].startTime);
    const endTime = getPickupEndTime(pickup);
    const duration = getPickupTotalDuration(pickup);

    return `${duration} hrs (${getFormattedTime(startTime)} - ${endTime})`;
  };

  const handleClickOpen = (binPickup: BinPickup): void => {
    setSelectedBinPickup(binPickup);
    setDetailModalOpen(true);
  };

  const handleClose = (): void => {
    setDetailModalOpen(false);
    setSelectedBinPickup(undefined);
  };

  const truckAssociatedWithBinPickup: TruckData | undefined =
    selectedBinPickup &&
    findTruckDataByName(selectedBinPickup.truckName, props.trucks);

  const formatDate = (
    timestamp: Date | string,
    format: 'date' | 'time' = 'time',
    defaultValue: string = '00:00 AM',
  ): string => {
    if (isNaN(new Date(timestamp).getTime())) return defaultValue;
    const date: Date = new Date(timestamp);
    if (format === 'date') {
      return `${date.getDate().toString().padStart(2, '0')}/${(
        date.getMonth() + 1
      )
        .toString()
        .padStart(2, '0')}/${date.getFullYear()}`;
    }
    return date.toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    });
  };

  return (
    <div className="scrollable-div">
      <TableContainer
        component={Paper}
        style={{ maxHeight: '75vh', position: 'relative' }}
      >
        <Table aria-label="simple table">
          {renderTableHeadersForHistory(props.historyCategory)}
          {props.data.map((dayHistoryForBin: BinHistory, index: number) => (
            <React.Fragment key={`day-${index}`}>
              <Typography
                variant="h6"
                className="dateGroup"
                style={{
                  margin: '16px',
                  marginTop: '20px',
                }}
              >
                {formatDate(dayHistoryForBin.date, 'date')}
              </Typography>
              <TableBody>
                {/* Render rows - Assuming the structure for binPickup can serve both bins and trucks with minimal adjustments */}
                {dayHistoryForBin.binHistories.map(
                  (binPickup: BinPickup, index: number) => {
                    if (
                      binPickup.status === PickupStatus.CANCELLED ||
                      binPickup.status === PickupStatus.IN_PROGRESS
                    ) {
                      return <></>;
                    }
                    return (
                      <TableRow
                        key={`${dayHistoryForBin.date}-${index}`}
                        onClick={() => handleClickOpen(binPickup)}
                      >
                        <TableCell>
                          <b>
                            {props.historyCategory === HistoryCategory.TRUCKS
                              ? binPickup.truckName
                              : `Bin ${binPickup.bin.name}`}
                          </b>
                        </TableCell>
                        <TableCell>{binPickup.pickupRef}</TableCell>

                        {props.historyCategory === HistoryCategory.TRUCKS ? (
                          <>
                            <TableCell>{`Bin ${binPickup.bin.name}`}</TableCell>
                            <TableCell>
                              {getPickupStatusWithDot(binPickup.status)}
                            </TableCell>
                            <TableCell>{upperFirst(binPickup.type)}</TableCell>
                            <TableCell>
                              {binPickup.operator
                                ? `${binPickup.operator.firstName} ${binPickup.operator.lastName}`
                                : '-'}
                            </TableCell>
                            <TableCell>{'None'}</TableCell>
                          </>
                        ) : (
                          <>
                            <TableCell>
                              {getPickupStatusWithDot(binPickup.status)}
                            </TableCell>
                            <TableCell>{upperFirst(binPickup.type)}</TableCell>
                            <TableCell>{binPickup.bin.scrapType}</TableCell>
                            <TableCell>{binPickup.truckName}</TableCell>
                            <TableCell>
                              {binPickup.operator
                                ? `${binPickup.operator.firstName} ${binPickup.operator.lastName}`
                                : '-'}
                            </TableCell>
                          </>
                        )}

                        <TableCell>{`${formatJobCycleDuration(
                          binPickup,
                        )}`}</TableCell>
                        <TableCell
                          onClick={(event) => {
                            event.stopPropagation();
                            props.onDownloadClick();
                          }}
                        >
                          <div className="d-flex align-items-center cursor-pointer">
                            <img
                              src={downloadIcon}
                              alt="download"
                              className="subheader-download"
                            />
                          </div>
                        </TableCell>
                      </TableRow>
                    );
                  },
                )}
              </TableBody>
            </React.Fragment>
          ))}
        </Table>
      </TableContainer>
      {selectedBinPickup?.bin.name && (
        <TrackBinModal
          binName={selectedBinPickup?.bin.name}
          binPickup={selectedBinPickup}
          isOpen={isDetailModalOpen}
          onClose={handleClose}
          truck={
            props.historyCategory === HistoryCategory.TRUCKS
              ? truckAssociatedWithBinPickup
              : undefined
          }
          fromHistoryTable={true}
        />
      )}
    </div>
  );
};

export default HistoryTable;
